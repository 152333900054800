export const routeParts = {
	annotation: 'annotation',
	assistant: 'assistant',
	widget: 'widget',
	settings: 'settings',
	infos: 'infos',
	report: 'report',
	accept: 'accept'
};

const portal = (portalRef: string, token?: string | null) =>
	token ? `/i/${portalRef}?t=${token}` : `/interventions/${portalRef}`;

const assistantPortals = (assistantRef: string) => `/a/${assistantRef}`;

const assistants = (assistantRef?: string) =>
	assistantRef ? `/assistant/${assistantRef}` : '/assistant';

const routes = {
	home: '/home',
	portals: '/interventions',
	login: '/login',
	register: '/register',
	pricing: '/pricing',
	pricingCredits: '/pricing/credits',
	contacts: '/contacts',
	statistics: '/statistics',
	procedures: '/procedures',
	adminPricing: '/admin/pricing',
	profile: '/profile',
	assistants: assistants,
	assistantsCreate: '/assistant/create',
	portal,
	assistantPortals,
	portalThenReport: (portalRef: string, token?: string | null) =>
		portal(portalRef, token) + '?openReport=true',
	portalAnnotation: (portalRef: string, token?: string | null) =>
		token
			? `/i/${portalRef}/${routeParts.annotation}?t=${token}`
			: `/interventions/${portalRef}/${routeParts.annotation}`,
	portalSettings: (portalRef: string, token?: string | null) =>
		token
			? `/i/${portalRef}/${routeParts.settings}?t=${token}`
			: `/interventions/${portalRef}/${routeParts.settings}`,
	portalReport: (portalRef: string, token?: string | null) =>
		token
			? `/i/${portalRef}/${routeParts.report}?t=${token}`
			: `/interventions/${portalRef}/${routeParts.report}`,
	portalInfos: (portalRef: string, token?: string | null) =>
		token
			? `/i/${portalRef}/${routeParts.infos}?t=${token}`
			: `/interventions/${portalRef}/${routeParts.infos}`,
	portalWidget: (portalRef: string, token?: string | null) =>
		token
			? `/i/${portalRef}/${routeParts.widget}?t=${token}`
			: `/interventions/${portalRef}/${routeParts.widget}`,
	portalAssistant: (portalRef: string, token?: string | null) =>
		token
			? `/i/${portalRef}/${routeParts.assistant}?t=${token}`
			: `/interventions/${portalRef}/${routeParts.assistant}`,
	portalAccept: (portalRef: string, token?: string | null, fromAssistant?: boolean) =>
		token
			? `/i/${portalRef}/${routeParts.accept}?t=${token}`
			: `/interventions/${portalRef}/${routeParts.accept}`,
	assistantAccept: (assistantRef: string, token?: string | null) =>
		`/a/${assistantRef}/${routeParts.accept}`
	// Continue adding routes here
};

export default routes;
